import Homepage from "@/views/Homepage";
import Book from "@/views/Book";
import Events from "@/views/Events";
import NotFound from '@/views/NotFound'

export default {
  mode: 'history',
  routes: [
    { path: '/', component: Homepage },
    { path: '/knyga/:slug', component: Book },
    { path: '/renginiai', component: Events },
    { path: '/404', component: NotFound },
    { path: '/:catchAll(.*)', redirect: '/404' }
  ]
}
