import ContentfulApi from "@/contentful/Api";

export default class ContentfulPageContent extends ContentfulApi {
    static async getBySlug(slug) {
        const query = `
            {
                collection:kbAppArticleCollection(limit: 1, where: {slug: "${slug}"}) {
                    items {
                        sys {
                            id
                        }
                    title,
                    body {
                        json  
                    },
                    photo {
                        title,
                        url
                    }                
                }
            }
        }`;

        return this.getSingle(query);
    }
}